import React, { useEffect, useState } from "react";
import DialogXXL from "./projectModal";
import { placeholderImage, isValidUrl } from "../../components/invalidImage";
import { fetchProjects } from "../../api/projectAPI";

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const projectsData = await fetchProjects();
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getProjects();
  }, []);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10 font-overpass ">
      <div className="text-center" id="ProjectsPage">
        <h1 className="font-extrabold text-4xl mb-3 heading">
          Project Kami
        </h1>
        <p className="mx-auto max-w-3xl base" style={{ fontSize: "15px" }}>
          Setiap Project dirancang dengan perhatian pada detail dan inovasi,
          berfokus pada menciptakan dampak positif yang berkelanjutan bagi
          lingkungan dan masyarakat.
        </p>
      </div>

      {error && <div className="text-red-500">{error}</div>}
      {projects.slice(0, 3).map((project, index) => (
        <div
          key={project.id}
          data-aos="zoom-in-up"
          className={`mx-auto flex flex-col md:flex-row gap-8 py-10 max-w-7xl mt-5 ${
            index % 2 !== 0 ? "md:flex-row-reverse" : ""
          }`}
        >
          <img
            src={isValidUrl(project.image) ? project.image : placeholderImage}
            alt={project.title}
            className="rounded-lg w-full md:w-1/2 object-cover mx-auto md:mx-0"
            style={{ height: "300px" }}
          />
          <div className="w-full md:w-1/2 px-4 md:px-10">
            <p className="text-sm font-semibold">OUR PORTFOLIO</p>
            <p className="text-3xl mt-3 font-bold">{project.title}</p>
            <p className="text-lg mt-5">{project.description}</p>
          </div>
        </div>
      ))}
      <div className="flex justify-center mb-8">
        <button
          onClick={openDialog}
          className="select-none rounded-full bg-sky-500 from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:bg-sky-700 active:opacity-[0.85]"
        >
          View More
        </button>
      </div>
      <DialogXXL isOpen={isDialogOpen} onClose={closeDialog} />
    </div>
  );
};

export default ProjectsPage;
