import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchClients } from "../../api/clientAPI";
import { placeholderImage, isValidUrl } from "../../components/invalidImage";

const ClientsPage = () => {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const [slidesToScroll, setSlidesToScroll] = useState(1);
  const [rows, setRows] = useState(1);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const getClients = async () => {
      try {
        const clientsData = await fetchClients();
        if (Array.isArray(clientsData)) {
          setClients(clientsData);
        } else {
          throw new Error("Clients data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getClients();
  }, []);

  useEffect(() => {
    setSlidesToShow(Number(localStorage.getItem("slidesToShow")) || 4);
    setSlidesToScroll(Number(localStorage.getItem("slidesToScroll")) || 1);
    setRows(Number(localStorage.getItem("rows")) || 1);
  }, []);

  useEffect(() => {
    localStorage.setItem("slidesToShow", String(slidesToShow));
  }, [slidesToShow]);

  useEffect(() => {
    localStorage.setItem("slidesToScroll", String(slidesToScroll));
  }, [slidesToScroll]);

  useEffect(() => {
    localStorage.setItem("rows", String(rows));
  }, [rows]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    rows: rows,
    autoplay: true,
    autoplaySpeed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow >= 3 ? 3 : slidesToShow,
          slidesToScroll: slidesToScroll,
          rows: rows,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slidesToShow >= 2 ? 2 : slidesToShow,
          slidesToScroll: slidesToScroll,
          rows: rows,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: rows,
        },
      },
    ],
  };

  return (
    <div className="w-full mx-auto my-16 mb-20 font-overpass ">
      {/* CSS Global untuk menyembunyikan overflow */}
      <style jsx global>{`
        body {
          overflow-x: hidden;
        }
      `}</style>

      <div className="text-center" id="ClientsPage">
        <h1 className="font-extrabold text-4xl mb-3 heading">Klien Kami</h1>
        <p className="mx-auto max-w-5xl mb-20  text-lg">
          Kami bangga menjadi mitra terpercaya bagi berbagai perusahaan dan
          organisasi di berbagai sektor industri. Klien-klien kami berasal dari
          beragam bidang, termasuk konstruksi, manufaktur, energi, dan banyak
          lagi.
        </p>
      </div>
      <div className="w-full overflow-hidden"> {/* Overflow hidden untuk slider */}
        {showSettings && (
          <div className="settings">{/* Render settings UI here */}</div>
        )}
        {clients.length > 0 ? (
          <Slider {...settings}>
            {clients.map((client) => (
              <div key={client.id} className="overflow-hidden rounded-lg">
                <div className="relative">
                  <div className="w-full h-48 flex justify-center items-center">
                    <img
                      src={
                        isValidUrl(client.image)
                          ? client.image
                          : placeholderImage
                      }
                      alt={client.name}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default ClientsPage;
