import React, { useEffect, useState } from "react";
import { fetchBlogs } from "../../../api/blogsAPI"; // Sesuaikan path jika perlu
import { placeholderImage, isValidUrl } from "../../../components/invalidImage";
import Header from "../../commons/Header2"; // Sesuaikan path jika perlu
import Footer from "../../commons/Footer";

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);
      } catch (error) {
        setError(error.message);
      }
    };

    getBlogs();
  }, []);

  const truncateText = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + "...";
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("id-ID", options).replace(" ", " ");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortOrder = (e) => {
    setSortOrder(e.target.value);
  };

  const filteredBlogs = blogs
    .filter((blog) => blog.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      } else {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      }
    });

  return (
    <div className="relative bg-white min-h-screen flex flex-col justify-between">
      <div className="fixed top-0 z-50 w-full">
        <Header />
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center pt-32 pb-5 gap-4 text-black z-10">
        <input
          type="text"
          placeholder="Search blogs..."
          className="px-4 py-2 border rounded-md w-64"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div className="flex flex-col sm:flex-row items-center gap-4">
          <label htmlFor="sortOrder" className="text-gray-700">
            Sort by date:
          </label>
          <select
            id="sortOrder"
            className="px-4 py-2 border rounded-md w-48"
            value={sortOrder}
            onChange={handleSortOrder}
          >
            <option value="desc">Newest First</option>
            <option value="asc">Oldest First</option>
          </select>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-8 px-6 mt-6 pb-10">
        {filteredBlogs.map((blog) => (
          <a href={`/blog/${blog.id}`} key={blog.id}>
            <div className="relative block w-80 h-80 transform hover:scale-105 transition duration-300 ease-in-out rounded-lg overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent rounded-lg"></div>
              <img
                className="w-full h-full object-cover"
                src={isValidUrl(blog.image) ? blog.image : placeholderImage}
                alt={blog.title}
              />
              <div className="absolute bottom-0 left-0 p-4 text-white">
                <p className="mb-2 text-sm font-bold tracking-tight">
                  {truncateText(blog.title, 100)}
                </p>
                <div
                  className="mb-3 text-xs"
                  dangerouslySetInnerHTML={{
                    __html: truncateText(blog.description, 80),
                  }}
                />
                <div className="text-gray-400 text-xs">
                  <div>{blog.author} | {formatDate(blog.created_at)}</div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
      {error && <div className="text-red-500 text-center">{error}</div>}
      <Footer />
    </div>
  );
};

export default BlogsPage;
