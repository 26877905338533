import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Main from "./pages/user/main";
import BlogsPage from "./components/user/blog/listBlog";
import BlogsDetail from "./components/user/blog/detailBlog";
import LoginForm from "./components/admin/login";
import ProtectedRoute from "./components/ProtectedRoute";
// import Auth0Callback from "./components/Auth0Callback";
import Callbackk from "./components/admin/callback";

import Dashboard from "./pages/admin/dashboard";
import Blog from "./pages/admin/blogs";
import Client from "./pages/admin/clients";
import Project from "./pages/admin/projects";
import Service from "./pages/admin/services";
import N404 from "./components/404";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/blog" element={<BlogsPage />} />
      <Route path="/blog/:id" element={<BlogsDetail />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/callback" element={<Callbackk />} />

      {/* Admin Page */}

      <Route
        path="/admin/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/blog"
        element={
          <ProtectedRoute>
            <Blog />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/client"
        element={
          <ProtectedRoute>
            <Client />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/project"
        element={
          <ProtectedRoute>
            <Project />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/service"
        element={
          <ProtectedRoute>
            <Service />
          </ProtectedRoute>
        }
      />

      {/* Default route: Redirect unknown paths */}
      <Route path="*" element={<N404 />} />
    </Routes>
  );
};

export default App;
