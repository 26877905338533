import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import wwt2 from "../../assets/bgwaste.jpg";

const ContactUs = () => {
  return (
    <div className="relative h-[400px] sm:h-[500px] md:h-[500px] overflow-hidden rounded-none p-4 shadow-lg font-overpass ">
      <img
        src={wwt2}
        alt="Background Image"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      />
      <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-start p-5 sm:p-10 text-white">
        <div className="text-left mx-5 sm:mx-10 z-10">
          <h1 className="mb-4 text-2xl sm:text-4xl font-semibold heading">
            Hubungi kami sekarang untuk solusi terbaik dalam memenuhi solusi
            limbah cair dan perizinan
          </h1>
          <div className="flex flex-col items-start mb-4 space-y-5">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faEnvelope} className="w-10 h-auto mr-5" />
              <a
                href="mailto:dwihandaya@gmail.com"
                className="text-white hover:underline"
              >
                dwihandaya@gmail.com
              </a>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faPhone} className="w-10 h-auto mr-5" />
              <a
                href="https://wa.me/6281286239674"
                className="text-white hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                +62 812-8623-9674
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
