import React, { useState, useEffect } from "react";
import { fetchClients } from "../../api/clientAPI"; // Import fungsi fetchClients
import { fetchProjects } from "../../api/projectAPI"; // Import fungsi fetchProjects
import { fetchServices } from "../../api/servicesAPI"; // Import fungsi fetchServices
import { fetchBlogs } from "../../api/blogsAPI"; // Import fungsi fetchBlogs

const DashboardC = ({ user, token }) => {
  const [clientCount, setClientCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);
  const [blogCount, setBlogCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);

  useEffect(() => {
    const getClients = async () => {
      try {
        const clients = await fetchClients();
        setClientCount(clients.length);
      } catch (error) {
        console.error("Failed to fetch clients:", error);
      }
    };

    const getProjects = async () => {
      try {
        const projects = await fetchProjects();
        setProjectCount(projects.length);
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    };

    const getServices = async () => {
      try {
        const services = await fetchServices();
        setServiceCount(services.length);
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    };

    const getBlogs = async () => {
      try {
        const blogs = await fetchBlogs();
        setBlogCount(blogs.length);

        // Calculate total comments from all blogs
        const totalComments = blogs.reduce((acc, blog) => acc + blog.comments.length, 0);
        setCommentCount(totalComments);
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
      }
    };

    getClients();
    getProjects();
    getServices();
    getBlogs();
  }, []);

  const renderCard = (title, count, link, buttonLabel) => (
    <div className="bg-gray-800 border border-gray-700 rounded-lg p-8 text-center w-full max-w-xl mx-auto">
      <h2 className="text-white text-3xl font-extrabold mb-4">{title}</h2>
      <p className="text-gray-400 text-lg font-normal mb-4">Total {title}: {count}</p>
      <a href={link} className="inline-block">
        <button
          type="button"
          className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
        >
          {buttonLabel}
        </button>
      </a>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8 bg-gray-900">
      <section className="w-full mx-auto">
        {/* Teks Selamat Datang di Dashboard Admin */}
        <div className="mb-12">
          <h1 className="text-white text-4xl font-bold mb-4">Hai Admin,</h1>
          <p className="text-gray-300 text-xl  mx-auto">
            Selamat datang di dashboard website admin. Semoga Anda merasa nyaman dan mendapatkan pengalaman yang menyenangkan dalam mengelola website ini. Kami siap membantu Anda dalam setiap langkah perjalanan Anda di sini.
          </p>
        </div>
        
        {/* Grid Kartu Admin */}
        <div className="grid md:grid-cols-2 gap-8 mt-10 ">
          {renderCard("Clients", clientCount, "/admin/client", "Client Page")}
          {renderCard("Projects", projectCount, "/admin/project", "Project Page")}
          {renderCard("Services", serviceCount, "/admin/service", "Service Page")}
          <div className="bg-gray-800 border border-gray-700 rounded-lg p-8 text-center w-full max-w-xl mx-auto">
            <h2 className="text-white text-3xl font-extrabold mb-4">Blogs</h2>
            <p className="text-gray-400 text-lg font-normal mb-4">Total Blogs: {blogCount}</p>
            <p className="text-gray-400 text-lg font-normal mb-4">Total Comments: {commentCount}</p>
            <a href="/admin/blog" className="inline-block">
              <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
              >
                Blog Page
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashboardC;
