import React, { useEffect, useState } from "react";
import { fetchBlogsRandom5 } from "../../../api/blogsAPI";
import { placeholderImage, isValidUrl } from "../../../components/invalidImage";
import { useParams } from "react-router-dom";

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const { blogId } = useParams();

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogData = await fetchBlogsRandom5();
        if (Array.isArray(blogData)) {
          setBlogs(blogData);
        } else {
          throw new Error("Blogs data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getBlogs();
  }, [blogId]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString)
      .toLocaleDateString("id-ID", options)
      .replace(" ", " ");
  };

  return (
    <div className="w-full md:ml-auto mx-4 md:mx-10">
      <h1 className="text-black text-2xl font-bold pb-5">Related Contents</h1>
      {error && <div className="text-red-500">{error}</div>}
      <div className="space-y-4">
        {blogs
          .filter((blog) => blog.id !== parseInt(blogId))
          .map((blog, index) => (
            <div
              key={blog.id}
              className="flex flex-col md:flex-row items-center md:items-start py-2 border-b-2 border-gray-300"
            >
              <a href={`/blog/${blog.id}`} className="w-full">
                <div className="flex items-center w-full space-x-4 md:space-x-2 mb-5">
                  <img
                    className="w-20 h-20 md:w-24 md:h-24 object-cover rounded-lg"
                    src={
                      isValidUrl(blog.image) ? blog.image : placeholderImage
                    }
                    alt={blog.title}
                  />
                  <div className="flex flex-col w-full">
                    <h3 className="text-left heading font-semibold text-gray-900 dark:text-white text-sm md:text-base leading-tight">
                      {blog.title.length > 50
                        ? blog.title.substring(0, 55) + "..."
                        : blog.title}
                    </h3>
                    <div className="flex text-xs md:text-sm text-gray-600 dark:text-gray-400">
                      <p>{blog.author}</p>
                      <span className="mx-1">|</span>
                      <p>
                        {new Date(blog.created_at).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BlogsPage;
