import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = () => {
    console.log('Logging out, removing tokens only');
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    
    logout({ returnTo: window.location.origin });
    navigate("/login");
  };
  

  const toggleMenu = () => {
    setOpen(!open);
  };

  const linkClass = (path) =>
    location.pathname === path
      ? "block px-4 py-2 mt-2 text-sm font-semibold text-white bg-gray-700 rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:text-white focus:outline-none focus:shadow-outline"
      : "block px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:text-white focus:outline-none focus:shadow-outline";

  return (
    <div className="md:flex flex-col w-full md:w-64 text-white bg-gray-800 dark:text-gray-200 flex-shrink-0 md:sticky md:top-0 md:h-screen md:overflow-y-auto z-10 h-full">
      <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
        <p className="text-lg font-semibold tracking-widest text-white uppercase rounded-lg dark:text-white focus:outline-none focus:shadow-outline">
          Bioaqualestari
        </p>
        <button
          className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
          onClick={toggleMenu}
        >
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
            {open ? (
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            ) : (
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            )}
          </svg>
        </button>
      </div>
      <nav
  className={`md:block ${
    open ? "block bg-gray-700" : "hidden"
  } md:static fixed w-full h-full top-0 left-0 md:w-auto md:h-auto`}
>
  <button
    className="absolute top-4 right-4 md:hidden focus:outline-none focus:shadow-outline"
    onClick={toggleMenu}
  >
    <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </button>
  <Link className={linkClass("/admin/dashboard")} to="/admin/dashboard">
    Dashboard
  </Link>
  <Link className={linkClass("/")} to="/">
    Halaman Utama
  </Link>
  <Link className={linkClass("/admin/client")} to="/admin/client">
    Client
  </Link>
  <Link className={linkClass("/admin/project")} to="/admin/project">
    Project
  </Link>
  <Link className={linkClass("/admin/service")} to="/admin/service">
    Service
  </Link>
  <Link className={linkClass("/admin/blog")} to="/admin/blog">
    Blog
  </Link>
  <button
    className="block px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg dark:bg-transparent hover:bg-gray-600 focus:bg-gray-600 focus:text-white focus:outline-none focus:shadow-outline"
    onClick={handleLogout}
  >
    Logout
  </button>
</nav>

    </div>
  );
};

export default Sidebar;
