import React, { useEffect, useState } from "react";
import { fetchServices } from "../../api/servicesAPI";
import { placeholderImage, isValidUrl } from "../invalidImage";

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getServices = async () => {
      try {
        const servicesData = await fetchServices();
        if (Array.isArray(servicesData)) {
          console.log(servicesData); // Check image paths
          setServices(servicesData);
        } else {
          throw new Error("Products data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getServices();
  }, []);

  const truncateDescription = (description) => {
    if (description.length <= 300) {
      return description;
    }
    return description.slice(0, 300) + "...";
  };

  return (
    <div className="bg-white font-sans min-h-96 mb-20 font-overpass " data-aos="zoom-in-up"  id="OurServices">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="heading text-3xl font-extrabold text-black inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-sky-600 after:rounded-full">
            Layanan Kami
          </h2>
        </div>
        <div className="flex justify-center mt-16 px-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-md:max-w-lg">
            {services.map((service) => (
              <div
                key={service.id}
                className="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative transition-all duration-300 group"
              >
                <img
                  src={
                    isValidUrl(service.image) ? service.image : placeholderImage
                  }
                  alt={`Service Post ${service.id}`}
                  className="w-full h-80 object-cover"
                />
                {/* Tambahkan div untuk shadow gradasi */}
                <div className="absolute inset-x-0 bottom-0 h-20 bg-gradient-to-t from-slate-950 to-transparent"></div>
                
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center p-6 text-white text-center">
                  <div>
                    <h3 className="text-xl font-bold">{service.name}</h3>
                    <hr className="my-6 border-gray-300" />
                    <p className="text-sm">
                      {truncateDescription(service.description)}
                    </p>
                  </div>
                </div>
                <h3 className="drop-shadow-2xl text-xl font-bold absolute bottom-4 left-4 z-10 text-white p-2 transition-opacity duration-300 group-hover:opacity-0">
                  {service.name}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
