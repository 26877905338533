import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { fetchBlogById } from "../../../api/blogsAPI";
import CommentSection from "./commentSection";
import RecentBlogs from "./relatedContents";
import { placeholderImage, isValidUrl } from "../../../components/invalidImage";
import Header from "../../commons/Header2";
import { useParams } from "react-router-dom";
import "./blog.css";
import { Grid, Row, Col } from "rsuite";
import { FaRegCalendarAlt, FaFolder, FaUser } from "react-icons/fa";

const BlogDetails = () => {
  const { id: blogId } = useParams(); // Mengambil blogId dari URL menggunakan useParams
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  debugger;

  useEffect(() => {
    if (blogId) {
      const getBlog = async () => {
        Swal.fire({
          title: "Loading...",
          text: "Please wait while we fetch the blog data.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          const blogData = await fetchBlogById(blogId);
          setBlog(blogData);
          Swal.close();
        } catch (error) {
          console.error("Error fetching blog data:", error);
          setError(error.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      };

      getBlog();
    }
  }, [blogId]);

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (!blog) {
    return null; // Loading handled by SweetAlert2
  }

  return (
    <>
      <Header />
      <Grid fluid className="mx-3 md:mx-20 pt-20">
        <Row className="show-grid">
          <Col xs={24} className="mb-5 md:mb-0">
            <div className="pt-4 md:pt-10 flex flex-wrap items-center space-x-4 md:space-x-8 text-gray-600 py-3 text-lg">
              <div className="flex items-center space-x-1">
                <FaRegCalendarAlt className="text-blue-500" />
                <span>{new Date(blog.created_at).toLocaleDateString()}</span>
              </div>
              <div className="flex items-center space-x-1">
                <FaFolder className="text-blue-500" />
                <span>Blogs</span>
              </div>
              <div className="flex items-center space-x-1">
                <FaUser className="text-blue-500" />
                <span>By {blog.author}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid fluid className="mx-3 md:ml-20 md:mr-10 mr-10 font-overpass ">
        <Row className="show-grid">
          <Col xs={24} sm={18} md={18} lg={18} className="mb-5 md:mb-0">
            <h1 className="text-2xl md:text-4xl font-semibold my-4 pb-3">
              {blog.title}
            </h1>
            <img
              src={blog.image}
              alt={blog.title}
              className="my-4 w-full h-60 md:h-1/6 object-cover rounded-lg"
            />
            <div
              className="text-lg my-2"
              dangerouslySetInnerHTML={{ __html: blog.description }}
            />
          </Col>
          <Col xs={24} sm={6} md={6} lg={6} className="pt-0 md:pt-5 pl-0 md:pl-10">
            <RecentBlogs />
          </Col>
        </Row>
      </Grid>
      <Grid fluid className="mx-3 md:mx-20 my-10">
        <Row className="show-grid">
          <Col xs={24} className="mb-5 md:mb-0">
            <CommentSection blog={blog} setBlog={setBlog} />
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default BlogDetails;
