import React from "react";
import about from "../../assets/image/1.5.JPG";
import about2 from "../../assets/image/1.1.jpeg";
import about3 from "../../assets/image/1.33.jpeg";

import { useEffect } from "react";

const AboutUs = () => {
  return (
    <section
      id="AboutUs"
      className="bg-gradient-to-b from-gray-300 via-gray-200 to-white pb-8 pt-20 dark:bg-dark-2 lg:pb-[70px] font-overpass "
    >
      <div className="container mx-auto px-4">
        <div>
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-1/2">
              <div
                data-aos="fade-right"
                className="mb-12 max-w-[540px] lg:mb-0"
              >
                <h2 className="mb-14 heading text-3xl font-extrabold text-black inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-sky-600 after:rounded-full mb-5 ">
                  Tentang Kami
                </h2>
                <p className="mb-10 text-lg leading-relaxed text-gray-700 dark:text-dark-6 text-justify">
                  Bioaqualestari adalah perusahaan yang bergerak di bidang
                  pengelolaan limbah cair dan perizinan lingkungan. Kami
                  berdedikasi untuk menyediakan solusi pengolahan limbah yang
                  inovatif dan ramah lingkungan bagi berbagai sektor industri.
                  <br />
                  <br />
                  Dengan tim ahli yang berpengalaman, kami menawarkan layanan
                  terpadu yang mencakup konsultasi, desain, instalasi, hingga
                  perawatan sistem pengolahan limbah.
                </p>

                {/* <a
                  href="#"
                  className="inline-flex items-center justify-center rounded-md border border-primary bg-primary px-7 py-3 text-center text-base font-medium text-white hover:border-blue-dark hover:bg-blue-dark"
                >
                  Know More
                </a> */}
              </div>
            </div>

            <div className="w-full px-4 lg:w-1/2" data-aos="fade-up">
              <div className="-mx-2 flex flex-wrap sm:-mx-4 lg:-mx-2 xl:-mx-4">
                <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                  <div className="mb-4 sm:mb-8 sm:h-[400px] md:h-[540px] lg:h-[400px] xl:h-[500px]">
                    <img
                      src={about2}
                      alt="about image"
                      className="h-full w-full object-cover object-center rounded-tl-3xl"
                    />
                  </div>
                </div>
                <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                  <div className="mb-4 sm:mb-8 sm:h-[220px] md:h-[346px] lg:mb-4 lg:h-[225px] xl:mb-8 xl:h-[310px]">
                    <img
                      src={about3}
                      alt="about image"
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="relative z-10 flex rounded-br-3xl items-center justify-center overflow-hidden bg-primary py-4 sm:mb-0 sm:h-[157px] lg:mb-0 xl:mb-0">
                    <div className="w-full">
                      <img
                        src={about}
                        alt="about image"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
