import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../commons/Header2";
import Footer from "../commons/Footer";
import logo from "../../assets/bal.png"; // Pastikan path ini benar

const FormLogin = () => {
  const [loginFailed, setLoginFailed] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const usernameRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const savedUsername = localStorage.getItem("savedUsername");

    if (token) {
      navigate("/admin/dashboard");
    } else {
      if (savedUsername) {
        usernameRef.current.value = savedUsername; // Set input value dengan username yang tersimpan
        setRememberMe(true); // Set checkbox "Remember Me" menjadi true jika ada username yang tersimpan
      }
      usernameRef.current.focus();
    }
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    const username = event.target.username.value.trim(); // Menghapus spasi di awal/akhir
    const password = event.target.password.value.trim(); // Menghapus spasi di awal/akhir
  
    // Validasi jika username atau password kosong
    if (!username) {
      setLoginFailed("Username tidak boleh kosong.");
      return; // Keluarkan fungsi jika username kosong
    }
  
    if (!password) {
      setLoginFailed("Password tidak boleh kosong.");
      return; // Keluarkan fungsi jika password kosong
    }
  
    // Validasi username hanya mengandung huruf dan angka
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    if (!usernameRegex.test(username)) {
      setLoginFailed("Username tidak boleh mengandung simbol atau karakter khusus.");
      return; // Keluarkan fungsi jika username tidak valid
    }
  
    setIsLoading(true);
  
    try {
      const response = await fetch(
        "https://backend.bioaqualestari.co.id/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Username or password is incorrect");
      }
  
      const data = await response.json();
      localStorage.setItem("accessToken", data.accessToken);
  
      if (rememberMe) {
        localStorage.setItem("savedUsername", username); // Simpan username jika "Remember Me" dicentang
      } else {
        localStorage.removeItem("savedUsername"); // Hapus username jika "Remember Me" tidak dicentang
      }
  
      if (data.accessToken) {
        navigate("/admin/dashboard");
      } else {
        throw new Error("Failed to obtain access token");
      }
    } catch (error) {
      setLoginFailed(
        error.message === "Username or password is incorrect"
          ? "Username atau password salah."
          : "Server sedang bermasalah, silahkan hubungi admin."
      );
      console.error("Gagal login:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <div>
      <Header></Header>
      <section className="bg-white">
        <div className="flex flex-col items-center justify-center px-6 mx-auto md:pt-44 pt-24">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              {/* Tambahkan gambar logo di sini */}
              <div className="flex justify-center">
                <img src={logo} alt="Logo" className="h-20 mb-4" /> {/* Sesuaikan ukuran logo */}
              </div>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your Username
                  </label>
                  <input
                    name="username"
                    id="username"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Username"
                    type="text"
                    ref={usernameRef}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    type="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  {loginFailed && <p className="text-red-600">{loginFailed}</p>}
                </div>
                <button
                  type="submit"
                  className={`bg-indigo-500 text-black w-full bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                    isLoading ? "cursor-not-allowed" : ""
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Sign in"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="pt-20">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default FormLogin;
