import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from "../../assets/bal.png"

const Footer = () => {
  return (
    <footer className="bg-sky-600 shadow dark:bg-sky-600" style={{ marginTop: 0 }}>
      <div className="w-full max-w-7xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
            <img width={200} height={200} src={Logo} className="h-17 w-14 px-2" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-black">
              Bio Aqua Lestari
              <br />
              <a className="text-sm font-medium text-white sm:mb-0 dark:text-gray-400 pt-20">PT. Bio Aqua Lestari</a>
            </span>
          </p>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-gray-400 list-none">
            <li>
              <a href="#AboutUs" className="hover:underline me-4 md:me-6">
                About
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send/?phone=6281286239674&text&type=phone_number&app_absent=0" className="hover:underline me-4 md:me-6">
                Calling Us
              </a>
            </li>
            {/* <li>
              <a href="#" className="hover:underline me-4 md:me-6">
                Maps
              </a>
            </li> */}
          </ul>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a> */}
          {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a> */}
          <a href="https://api.whatsapp.com/send/?phone=6281286239674&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
          <a href="https://www.instagram.com/dwihandoyomk3" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.linkedin.com/in/dwi-handoyo-68b90217/" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-6" />
        <center>
          <span className="block text-sm text-white sm:text-center dark:text-white">
            © 2024{" "}
            <a href="" className="hover:underline">
              Bioaqualestari Web
            </a>
            . All Rights Reserved.
          </span>
        </center>
      </div>
    </footer>
  );
};

export default Footer;
