import React, { useEffect, useState } from "react";
import { fetchBlogsRandom } from "../../../api/blogsAPI";
import { placeholderImage, isValidUrl } from "../../../components/invalidImage";
import AOS from "aos";
import "aos/dist/aos.css";


const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);


useEffect(() => {
  AOS.init({});
}, []);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogsRandom();
        setBlogs(blogsData);
      } catch (error) {
        setError(error.message);
      }
    };

    getBlogs();
  }, []);

  const truncateText = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + "...";
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('id-ID', options).replace(' ', ' ');
  };

  return (
    <div className="relative overflow-hidden" data-aos="fade-up-right">
      <div className="flex flex-wrap justify-center gap-8 px-6 mt-6" id="BlogsPage">
        <div className="flex flex-col sm:flex-row items-center justify-between w-screen mx-0 md:mx-16">
          <h2 className="text-2xl font-bold text-gray-800 mb-2 sm:mb-0 heading">
            Popular blog posts
          </h2>
          <div className="flex-grow mx-4 border-t border-gray-300 w-full sm:w-auto sm:border-t-4 sm:border-l sm:h-0 sm:ml-4 sm:mr-4"></div>
          <a href="/blog" className="text-blue-600 hover:underline py-5">
            Explore Bioaqualestari Blogs
          </a>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-8 px-6 mt-6 pb-10">
        {blogs.map((blog) => (
          <a href={`/blog/${blog.id}`} key={blog.id}>
            <div className="relative block w-80 h-80 transform hover:scale-105 transition duration-300 ease-in-out rounded-lg overflow-hidden">
              <div
                className="absolute inset-0 bg-gradient-to-t from-black to-transparent rounded-lg"
              ></div>
              <img
                className="w-full h-full object-cover"
                src={isValidUrl(blog.image) ? blog.image : placeholderImage}
                alt={blog.title}
              />
              <div className="absolute bottom-0 left-0 p-4 text-white">
                <p className="mb-3 text-sm font-bold tracking-tight">
                  {truncateText(blog.title, 100)}
                </p>
                <div className="text-gray-400 text-xs">
                  <div>{blog.author} | {formatDate(blog.created_at)}</div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 w-full h-auto z-[-1] mb-[-1px]"
      >
        <path
          fill="#0284C7"
          fillOpacity={1}
          d="M0,224L1440,0L1440,320L0,320Z"
        />
      </svg>
      {error && <div className="text-red-500 text-center">{error}</div>}
    </div>
  );
};

export default BlogsPage;
