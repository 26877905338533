import Header from "../../components/commons/Header";

//-----------Content-------------------
import Client from "../../components/user/clients";
import Services from "../../components/user/services";
import Project from "../../components/user/project";
import Blogs from "../../components/user/blog/mainBlog";
import Footer from "../../components/commons/Footer";
import LandingPage from "../../components/user/landingPage2";
import AboutUs from "../../components/user/aboutUs2";
import Contact from "../../components/user/contact";
import OurAdvantages from "../../components/user/advantage";
import WhatsAppButton from "../../components/user/whatsapp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Main() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <Header></Header>
      <LandingPage></LandingPage>
      <AboutUs></AboutUs>
      <div className="py-5">
        <Services></Services>
      </div>

      <Contact></Contact>
      <Project></Project>
      <OurAdvantages></OurAdvantages>
      <Client></Client>
      <Blogs></Blogs>
      <WhatsAppButton></WhatsAppButton>
      <Footer></Footer>
    </>
  );
}
