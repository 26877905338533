import wwt from "../../assets/wastewater.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const LandingPage = () => {
  useEffect(() => {
    AOS.init({});
  }, []);

  const scrollToSection = (sectionId, headerOffset) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-white overflow-hidden">
      <div className="absolute inset-0 z-0">
        <img src={wwt} alt="Wastewater" className="w-full h-full object-cover" />
      </div>
      <div className="absolute inset-0 bg-black opacity-55 z-10"></div>
      <div className="relative z-20 flex flex-col items-center text-center px-4">
        <h5
          data-aos="fade-left"
          className="text-3xl md:text-5xl font-bold text-white mb-4 playfair-display shadow-text"
        >
          Solusi Terpadu untuk Pengelolaan Limbah Cair
        </h5>
        <p
          data-aos="fade-right"
          className="text-slate-200 font-overpass text-base md:text-lg mt-3"
        >
          Bergabunglah dengan kami untuk menjaga kelestarian air dan lingkungan
        </p>
        {/* <button className="mt-10 bg-orange-600 text-white py-2 px-6 rounded-md hover:bg-orange-700 hover:scale-105 transform transition-transform duration-200 shadow-md hover:shadow-lg mb-6">
          READ THE BLOG
        </button> */}
      </div>
      <div className="absolute bottom-10 left-0 right-0 flex justify-between items-center px-10 md:px-80 z-30">
        <div className="flex flex-col items-center">
          <button
            onClick={() => scrollToSection("AboutUs", 70)} // Offset untuk "TENTANG KAMI"
            className="text-white font-fira-sans-condensed-regular text-sm hover:scale-105 transform transition-transform duration-200 shadow-text"
          >
            <span className="no-underline hover:no-underline hover:text-white">
              TENTANG KAMI
            </span>
          </button>
          <div className="h-0.5 w-full bg-white mt-1"></div>
        </div>
        <div className="flex flex-col items-center">
          <button
            onClick={() => scrollToSection("OurServices", 350)} // Offset untuk "LAYANAN KAMI"
            className="text-white font-fira-sans-condensed-regular text-sm hover:scale-105 transform transition-transform duration-200 shadow-text"
          >
            <span className="no-underline hover:no-underline hover:text-white">
              LAYANAN KAMI
            </span>
          </button>
          <div className="h-0.5 w-full bg-white mt-1"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
