import React from "react";
import environment from "../../assets/logo/environment.svg";
import handshakeIcon from "../../assets/logo/handshake.svg";
import price from "../../assets/logo/price.svg";
import team from "../../assets/logo/team.svg";
import experience from "../../assets/logo/experience.svg";
import quality from "../../assets/logo/quality.svg";

const data = [
  {
    title: "Pengalaman dan Keahlian",
    text: "Tim ahli Bioaqualestari memiliki pengalaman bertahun-tahun dalam pengelolaan limbah cair, serta rekam jejak proyek sukses di berbagai industri.",
    imageUrl: experience,
  },
  {
    title: "Pelayanan Terpadu",
    text: "Layanan kami mencakup perizinan lingkungan yang cepat dan lengkap, serta konsultasi dan dukungan teknis dari awal hingga akhir proyek.",
    imageUrl: handshakeIcon,
  },
  {
    title: "Komitmen terhadap Kualitas",
    text: "Kami menerapkan standar tinggi dalam setiap tahap pekerjaan dengan sistem pengawasan mutu yang ketat untuk memastikan hasil terbaik.",
    imageUrl: quality,
  },
  {
    title: "Keberlanjutan dan Kepedulian Lingkungan",
    text: "Kami fokus pada solusi yang berkelanjutan dan mendukung pelestarian lingkungan dengan meminimalkan dampak negatif.",
    imageUrl: environment,
  },  
  {
    title: "Tim yang Berdedikasi",
    text: "Tim kami terdiri dari profesional yang berdedikasi dan berkomitmen untuk memberikan layanan terbaik dengan keterampilan optimal.",
    imageUrl: team,
  },
  {
    title: "Harga Kompetitif",
    text: "Kami menawarkan harga yang bersaing dan transparan, memberikan nilai tambah yang tinggi tanpa biaya tersembunyi.",
    imageUrl: price,
  },
];

const OurAdvantages = () => {
  return (
    <div id="OurAdvantages" className="bg-sky-600 text-white py-12 font-overpass ">
      <div className="text-center mb-16">
        <h2 className="text-white heading text-3xl font-extrabold text-black inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-green-400 after:rounded-full">
          Kelebihan Kami
        </h2>
      </div>
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {data.map((item, index) => (
            <div className="flex flex-col items-center text-center" key={index}>
              <div className="mb-4 min-h-20">
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  width={60}
                  height={60}
                  className="mx-auto"
                />
              </div>
              <h1 className="text-xl font-bold mb-2 heading">{item.title}</h1>
              <p className="base text-s text-justify">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurAdvantages;
